.card {
    background-color: rgba(255, 255, 255, 0.05) !important;
    backdrop-filter: blur(50px) !important;
    -webkit-backdrop-filter: blur(50px) !important;
    padding: 1rem !important;
    border-radius: 1rem!important;
    border: none !important;
    box-shadow: 0 20px 30px -20px rgba(5, 5, 5, 0.24);
    flex: 1 !important;
    min-width: 250px !important;
    transition: all 0.3s ease-in-out !important;
    min-height: 270px;
    user-select: none;
    -webkit-user-select: none;
  }
  
  .card:hover{
    background-color: rgba(255, 255, 255, 0.08) !important;
  }

  .header {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    color: #ccc;
    text-align: center;
  }
  
  .header .image {
    height: 4rem;
    width: 4rem;
    border-radius: 9999px;
    object-fit: cover;
    background-color: royalblue;
  }
  
  .stars {
    display: flex;
    justify-content: center;
    grid-gap: 0.125rem;
    gap: 0.125rem;
    color: #f8a323;
    justify-content: center !important;
    flex: 1 !important;
  }
  
  .stars svg {
    height: 1rem;
    width: 1rem;
  }
  
  .name {
    margin-top: 0.25rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: #eee;
  }
  
  .message {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-top: 0.5rem;
    color: #ccc;
  }