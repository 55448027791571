.container {
  flex: 1;
  min-height: 100vh !important;
  height: 100vh !important;
  max-height: 100vh !important;
  height: max-content;
  background: #0d0d13;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  z-index: 100;
}

.videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh !important;
  height: 100vh !important;
  max-height: 100vh !important;
  bottom: 0;
  z-index: 101;
  overflow: hidden;
  filter: brightness(0.4) contrast(1.2) sepia(0.8) hue-rotate(-20deg);
}

.overlay {
  flex: 1;
  width: 100vw;
  min-height: 100vh !important;
  height: 100vh !important;
  max-height: 100vh !important;
  object-fit: cover;
  pointer-events: none;
  filter: blur(50px);
  animation: fadeIn 1s ease-in;
  transform: scale(1.5) !important;
}


.contentContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 102;
  overflow: hidden;
  background: linear-gradient(
      75deg,
      rgba(179, 178, 251, 0.5) 0%,
      rgba(179, 178, 251, 0.25) 50%,
      rgba(179, 178, 251, 0.5) 100%
    )
    0% 0% / auto repeat scroll padding-box border-box rgba(0, 0, 0, 0);
  mix-blend-mode: multiply;
  opacity: 1;
}
.logoContainer {
  position: relative;
  font-weight: bolder;
  width: 12rem;
  height: 6rem;
  color: #ccc;
  z-index: 103;
  overflow: hidden;
  background-image: url("../../Assets/logo/3\ a.png");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  animation-delay: 0.5s;
  animation: fadeIn 0.5s ease-in forwards;
}

/* .logoContainer::before {
  content: "";
  background-image: url("../../Assets/logo/3\ a.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--width-percentage, 0%);
  height: 100%;
  z-index: 102;
  transition: width 0.3s ease-in-out;
} */

.loadingContainer {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 103;
  animation-delay: 0.5s;
  animation: fadeIn 0.5s ease-in;
  /* transform: translateY(300px); */
}

.loading {
  color: #ccc;
  max-width: clamp(200px, 60vw, 300px);
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loadingBar {
  background: #ccc !important;
  color: #fff !important;
  width: 100% !important;
}

/* ANIMATIONS */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInBottom {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* RESPONSIVE BREAKPOINTS */

@media (max-width: 1200px) {
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .logoContainer {
    font-size: 3.5rem;
  }
}

@media (max-width: 480px) {
  .logoContainer {
    font-size: 3rem;
  }
}
