
.container{
    text-align: left;
    padding: 3rem 3rem;
}

.titleColumnTitle{
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    line-height: 4rem;
    text-align: left;
}

.serviceColumn {
    background-color: #181822;
    border-radius: 1.5rem;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    flex: 1;
    transition: all 0.3s ease-in-out;
    height: max-content !important;
    align-items: stretch !important;
    gap: 15px;
    transition: all 0.3s ease-in-out;

}
/* .serviceColumn:hover{
    box-shadow: 0px 0px 50px 5px rgba(34,163,239, 0.3) !important;
} */

.serviceColumnTitle{
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 5px;
}

.serviceColumnPara{
    font-size: 1rem;
    color: #eee;
}

.serviceColumnIcon{
    font-size: 1rem;
}

.readMoreButton{
    background: transparent;
    color: #fff;
    border: none;
    outline: none;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    transform: scale(1);
    padding-left: 0px;
    margin-top: 20px;
}

.readMoreButton:hover{
    transform: scale(1.05);

}

.iconService{
    display: flex;
}


@media (max-width: 767px) {

    .container{
        padding: 1rem;
    }

    
.titleColumnTitle{
    font-size: 2.3rem;
    line-height: 2.5rem;
}

.iconService{
    display: none;
}

  }