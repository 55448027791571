.socialButtonContainer{
    display: flex;
    flex: 1;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
}

.socialButton {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: none;
    background: #0866ff;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    transition: 0.1s;
  }



  .whatsappButtonMain {
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    border: none;
    background: linear-gradient(120deg, #02ff2c, #008a12);
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    transition: 0.1s ease-in-out;
  }

  .whatsappButtonMain:hover{
    transform: scale(1.1);
  }

  
.whatsappButtonMain:active{
  transform: scale(0.9);
}

  .whatsappButtonMain svg {
    color: white;
    width: 40px;
    height: 40px;
    z-index: 9;
  }
  


  .socialButton svg {
    color: white;
    width: 20px;
    height: 20px;
    z-index: 9;
  }
  
  .socialButton:nth-child(2) {
    background: linear-gradient(120deg, #833ab4, #fd1d1d, #fcb045);
  }
  
  .socialButton:nth-child(3) {
    background: #0a66c2;
  }
  
  .socialButton:nth-child(4) {
    background: rgb(255, 33, 33);
  }
  
  .socialButton:nth-child(5) {
    background: black;
  }
  
  .socialButton:active {
    transform: scale(0.85);
  }
  
  .socialButton::before {
    content: "";
    position: absolute;
    width: 35px;
    height: 35px;
    background-color: #212121;
    border-radius: 50px;
    z-index: -1;
    border: 0px solid rgba(255, 255, 255, 0.411);
    transition: 0.4s;
  }

  .whatsappButtonMain::before {
    content: "";
    position: absolute;
    width: 65px;
    height: 65px;
    background-color: #212121;
    border-radius: 50px;
    z-index: -1;
    border: 0px solid rgba(255, 255, 255, 0.411);
    transition: 0.4s;
    width: 0px;
    height: 0px;
  }
  
  /* .whatsappButtonMain:hover::before {
    width: 0px;
    height: 0px;
  } */
  
  
  .socialButton:hover::before {
    width: 0px;
    height: 0px;
  }
  