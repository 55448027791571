.portfolioCard {
  position: relative;
  width: 100%;
  background-color: transparent;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  perspective: 1000px;
  box-shadow: 0 0 0 5px #ffffff80;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  aspect-ratio: 3 / 2.5; /* This maintains the 3:2 aspect ratio */
}


.portfolioCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top !important;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.portfolioCard:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
}

.portfolioCard__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: rgba(22, 22, 30, 0.9);
  /* backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px); */
  transform: rotateX(-90deg);
  transform-origin: bottom;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.portfolioCard:hover .portfolioCard__content {
  transform: rotateX(0deg);
}

.portfolioCard__title {
  margin: 0;
  font-size: 20px;
  color: #eee;
  font-weight: 700;
}

.portfolioCard:hover svg {
  scale: 0;
}

.portfolioCard__description {
  margin: 5px 0 0;
  font-size: 13px;
  color: #ddd;
  line-height: 1.4;
}
