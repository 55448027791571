.Footer{
  text-align: center;
  flex: 1
}

.para{
  color: #ccc;
  max-width: 700px;
  margin: 10px auto;
}


@media (max-width: 767px) {
}