@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=New+Amsterdam&family=Oswald:wght@200..700&display=swap');



body {
  margin: 0;
  /* font-family: "Raleway", serif !important; */
  scroll-behavior: smooth;
  user-select: none;
  -webkit-user-select: none;

  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

  /* font-family: 'Roboto', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35)); */
}


.socialArea{
  position: fixed;
  bottom: 20px;
  right: 15px;
  z-index: 100;
  transform: translateX(100px);
}