.mainImage {
  flex: 1;
  padding-top: 5rem;
  padding: 1rem;
  overflow: hidden;
  /* max-width: 1200px; */
  margin: 0px auto;
  margin-bottom: 3rem;
}

.emailContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  flex-wrap: wrap;
}

.mainHeading {
  font-size: 5rem;
  padding-top: 2rem;
  padding-left: 2rem;
}



.title{
  font-size: 3rem;
  font-weight: bold;
  align-self: center;
  margin: 10px auto;
  flex: 1;
  text-align: center;
  margin-bottom: 0px !important;
}

@media (max-width: 767px) {


.title{
  font-size: 2rem;
}
}

@media (max-width: 900px) {
  .mainHeading {
    font-size: 5rem;
    padding-top: 0rem;
    padding-left: 0rem;
  }
}

@media (max-width: 650px) {
  .mainImage {
    padding: 10px;
  }
  .emailContainer {
    flex-direction: column;
    gap: 0px;
  }
}

.mainImageComponent {
  flex: 1;
  padding-top: 3rem;
}

.mainProductImage {
  height: auto;
  max-height: 30rem;
  width: 80%;
  object-fit: contain;
}

.mainImageText {
  padding: 5rem;
  padding-top: 1rem;
}

.contactPage {
  font-family: "Helvetica Neue", Arial, sans-serif;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.header {
  background-color: #1f42a0;
  color: #ffffff;
  padding: 1rem 0;
  margin-bottom: 2rem;
}

.header h1 {
  font-size: 2rem;
}

.contactContent {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1500px;
}

.contactInfo,
.contactForm,
.contactFormSecond {
  flex: 1;
  padding: 2rem;
  padding-bottom: 0;
}

.contactForm {
  text-align: left;
}
.contactFormSecond {
  text-align: left;
}

.formGroup {
  margin-bottom: 1rem;
}

.formGroupLabel {
  display: block;
  font-weight: bold;
}

.formGroup input,
.formGroup textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.formGroup textarea {
  resize: vertical;
}

.contactImage {
  width: 100%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.radioGroup {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}

.radioGroup input[type="radio"] {
  margin-right: 0.5rem;
}

.additional {
  background-color: #ffffff;
  /* background-color: #fcedea; */
  padding: 1rem;
  margin: 1rem;
  margin-top: 3rem;
}

/* Add responsive styles for the contact form */
@media (max-width: 768px) {
  .contactContent {
    flex-direction: column;
    align-items: center;
  }

  .contactForm {
    margin-top: 2rem;
    width: 90vw;
    margin-bottom: 0rem;
  }

  .contactFormSecond {
    margin-top: 0rem;
    width: 90vw;
    height: 100%;
    background-color: #303030;
  }

  .responsiveStudentsImage {
    max-width: 80%;
    height: auto;
  }

  .formContainer {
    width: 100%;
  }

  .contactForm {
    text-align: center;
  }
}
