
.container{
    flex: 1;
    text-align: left;
    padding: 3rem 3rem;
    /* display: flex;
    flex-wrap: wrap;
    gap: 10px; */
}

.titleColumnTitle{
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    line-height: 4rem;
    text-align: left;
}

.serviceColumn{
    background-color: #181822;
    border-radius: 1.5rem;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
    overflow: hidden;
}

.serviceColumnTitle{
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
}

.serviceColumnPara{
    font-size: 1rem;
    color: #ccc;
}

.serviceColumnIcon{
    font-size: 1rem;
}

.readMoreButton{
    background: transparent;
    color: #fff;
    border: none;
    outline: none;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
    transform: scale(1);
    padding-left: 0px;
    margin-top: 20px;
}

.readMoreButton:hover{
    transform: scale(1.05);

}

.ourTeamImage{
    min-width: 100% !important; 
    height: 300px!important;
    object-fit: cover!important;
    object-position: center!important;
}

.ourTeamCol{
    padding: 0px !important;
    border-radius: 1rem !important;
    min-width: 200px !important;
}

.firstCol{
    text-align: left;
}


@media (max-width: 767px) {

    .container{
        padding: 0rem !important;
    }
    .ourTeamImage{
        height: 150px!important;
    }
    .titleColumnTitle{
        font-size: 2.3rem;
        line-height: 2.5rem;
    }
  }