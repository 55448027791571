.textContainer{
    flex: 1;
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
    z-index: 21;
    user-select: none !important;
    -webkit-user-select: none !important;
}

.imageContainer{
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
}


/* ___TEXTCONTAINER__ */
.upperText{
    color: #fff;
    font-size: 1.1em;
    font-weight: bold;
}

.middleText{
    color: #fff;
    font-size: 5em;
    font-weight: bold;
    line-height: 1em;
}


.image{
    width: 100%;
    height: auto;
    object-fit: contain;
}

@media (max-width: 767px) {
    .upperText{
        font-size: 1em;
    }
    
    .middleText{
        font-size: 3.5em;
    }
  }